import ResolutionOfSojourner from './ResolutionOfSojourner';
import BraveHeart from './BraveHeart';
import DefendersWill from './DefendersWill';
import TinyMiracle from './TinyMiracle';
import Berserker from './Berserker';
import MartialArtist from './MartialArtist';
import Instructor from './Instructor';
import Gambler from './Gambler';
import TheExile from './TheExile';
import Adventurer from './Adventurer';
import LuckyDog from './LuckyDog';
import Scholar from './Scholar';
import TravelingDoctor from './TravelingDoctor';
import BlizzardStrayer from './BlizzardStrayer';
import Thundersoother from './Thundersoother';
import Lavawalker from './Lavawalker';
import MaidenBeloved from './MaidenBeloved';
import GladiatorsFinale from './GladiatorsFinale';
import ViridescentVenerer from './ViridescentVenerer';
import WanderersTroupe from './WanderersTroupe';
import ThunderingFury from './ThunderingFury';
import CrimsonWitchOfFlames from './CrimsonWitchOfFlames';
import NoblesseOblige from './NoblesseOblige';
import BloodstainedChivalry from './BloodstainedChivalry';
import PrayersForIllumination from './PrayersForIllumination';
import PrayersForDestiny from './PrayersForDestiny';
import PrayersForWisdom from './PrayersForWisdom';
import PrayersToSpringtime from './PrayersToSpringtime';
import ArchaicPetra from './ArchaicPetra';
import RetracingBolide from './RetracingBolide';
import HeartOfDepth from './HeartOfDepth';
import TenacityOfTheMillelith from './TenacityOfTheMillelith';
import PaleFlame from './PaleFlame';
import ShimenawasReminiscence from './ShimenawasReminiscence';
import EmblemOfSeveredFate from './EmblemOfSeveredFate';
import HuskOfOpulentDreams from './HuskOfOpulentDreams';
import OceanHuedClam from './OceanHuedClam';
import VermillionHereafter from './VermillionHereafter';
import EchoesOfAnOffering from './EchoesOfAnOffering';
const artifacts = { ResolutionOfSojourner, BraveHeart, DefendersWill, TinyMiracle, Berserker, MartialArtist, Instructor, Gambler, TheExile, Adventurer, LuckyDog, Scholar, TravelingDoctor, BlizzardStrayer, Thundersoother, Lavawalker, MaidenBeloved, GladiatorsFinale, ViridescentVenerer, WanderersTroupe, ThunderingFury, CrimsonWitchOfFlames, NoblesseOblige, BloodstainedChivalry, PrayersForIllumination, PrayersForDestiny, PrayersForWisdom, PrayersToSpringtime, ArchaicPetra, RetracingBolide, HeartOfDepth, TenacityOfTheMillelith, PaleFlame, ShimenawasReminiscence, EmblemOfSeveredFate, HuskOfOpulentDreams, OceanHuedClam, VermillionHereafter, EchoesOfAnOffering };
export default artifacts;