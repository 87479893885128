import Albedo from './Albedo';
import Amber from './Amber';
import Barbara from './Barbara';
import Beidou from './Beidou';
import Bennett from './Bennett';
import Chongyun from './Chongyun';
import Diluc from './Diluc';
import Diona from './Diona';
import Fischl from './Fischl';
import Ganyu from './Ganyu';
import HuTao from './HuTao';
import Jean from './Jean';
import Kaeya from './Kaeya';
import Keqing from './Keqing';
import Klee from './Klee';
import KujouSara from './KujouSara';
import KukiShinobu from './KukiShinobu';
import Lisa from './Lisa';
import Mona from './Mona';
import Ningguang from './Ningguang';
import Noelle from './Noelle';
import Qiqi from './Qiqi';
import Razor from './Razor';
import Sucrose from './Sucrose';
import Tartaglia from './Tartaglia';
import Traveler from './Traveler';
import RaidenShogun from './RaidenShogun';
import Venti from './Venti';
import Xiangling from './Xiangling';
import Xiao from './Xiao';
import Xingqiu from './Xingqiu';
import Xinyan from './Xinyan';
import Rosaria from './Rosaria';
import Yanfei from './Yanfei';
import Eula from './Eula';
import KaedeharaKazuha from './KaedeharaKazuha';
import KamisatoAyaka from './KamisatoAyaka';
import Sayu from './Sayu';
import Shenhe from './Shenhe';
import Yoimiya from './Yoimiya';
import Aloy from './Aloy';
import SangonomiyaKokomi from './SangonomiyaKokomi';
import Thoma from './Thoma';
import Gorou from './Gorou';
import AratakiItto from './AratakiItto';
import YaeMiko from './YaeMiko';
import YunJin from './YunJin';
import Zhongli from './Zhongli';
import KamisatoAyato from './KamisatoAyato';
import Yelan from './Yelan';
import ShikanoinHeizou from './ShikanoinHeizou';
const characters = { Albedo, Amber, Barbara, Beidou, Bennett, Chongyun, Diluc, Diona, Fischl, Ganyu, HuTao, Jean, Kaeya, Keqing, Klee, KujouSara, KukiShinobu, Lisa, Mona, Ningguang, Noelle, Qiqi, Razor, Sucrose, Tartaglia, Traveler, RaidenShogun, Venti, Xiangling, Xiao, Xingqiu, Xinyan, Rosaria, Yanfei, Eula, KaedeharaKazuha, KamisatoAyaka, Sayu, Shenhe, Yoimiya, Aloy, SangonomiyaKokomi, Thoma, Gorou, AratakiItto, YaeMiko, YunJin, Zhongli, KamisatoAyato, Yelan, ShikanoinHeizou };
export default characters;